@media print {
    /* Add print-specific styles here */

    #root {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0 1fr 0;
        background-image: none;
    }
    button,
    .p-button,
    header,
    footer {
        display: none !important;
    }

    .p-panel {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
@media screen {
    /* TODO MEDIA QUERY THE SIDES ETC*/
    @media (min-width: 320px) {
        /* smartphones, iPhone, portrait 480x320 phones */
    }
    @media (min-width: 481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }
    @media (min-width: 641px) {
        /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    }
    @media (min-width: 961px) {
        .search input {
            min-width: 320px;
        }
        /* tablet, landscape iPad, lo-res laptops ands desktops */
    }
    @media (min-width: 1025px) {
        /* big landscape tablets, laptops, and desktops */
        .page-container {
            display: flex;
            flex-flow: column;
            padding: 20px;
            /* overflow: auto; */
        }
    }
    @media (min-width: 1281px) {
        /* hi-res laptops and desktops */
    }

    /* todo use theme background color */

    header {
        background-color: rgb(40, 66, 97);
        display: flex;
        color: white;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
    }
    header > * {
        display: flex;
    }
    header .app-header-right {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    header .app-header-right > * {
        padding: 0 4px;
    }

    .app-title {
        display: flex;
        text-decoration: none;
        color: white;
        margin-left: 12px;
    }

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #root {
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 50px 2fr 50px;
        background-image: linear-gradient(180deg, #2f6ed3 5%, #5095e4 20%, #5095e4 60%, #2f6ed3 95%);
    }

    main {
        padding: 0.5rem;
        flex: 1;
    }

    footer {
        /* background-color: rgba(255, 223, 137, 0.863); */
        background-color: rgb(40, 66, 97);
        color: white;
        padding: 0.2rem;
    }

    .search {
        margin-right: 8px;
    }
    a.primary {
        color: rgb(40, 66, 97);
    }
    a:link {
        text-decoration: none;
    }
    a:visited {
        text-decoration: none;
    }
    a:active {
        text-decoration: none;
    }
    a:hover {
        text-decoration: none;
        /* background: #0d89ec; */
        color: #0d89ec;
    }
    .tag-editor {
        background-color: #fff;
        padding: 0.5em;
    }
    .image-field {
        display: flex;
        flex-direction: row;
    }
}
