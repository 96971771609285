.dataview-recipes .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.dataview-recipes .recipe-name {
    font-size: 1.3rem;
    font-weight: 700;
}

.dataview-recipes .recipe-description {
    /* margin: 0 0 1rem 0; */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.dataview-recipes .recipe-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.dataview-recipes .recipe-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview-recipes .recipe-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview-recipes .recipe-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview-recipes .recipe-list-item .recipe-list-detail {
    flex: 1 1 0;
}

.dataview-recipes .recipe-list-item .p-rating {
    margin: 0 0 0.5rem 0;
}

.dataview-recipes .recipe-list-item .recipe-price {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
}

.dataview-recipes .recipe-list-item .recipe-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-recipes .recipe-list-item .p-button {
    margin-bottom: 0.5rem;
}

.dataview-recipes .recipe-grid-item {
    margin: 0.5em;
    border: 1px solid #dee2e6;
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-flow: column;
}
.dataview-recipes .recipe-grid-item-wrapper {
    padding: 0.5rem;
}

.dataview-recipes .recipe-grid-item .recipe-grid-item-top,
.dataview-recipes .recipe-grid-item .recipe-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview-recipes .recipe-grid-item img {
    max-width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview-recipes .recipe-grid-item .recipe-grid-item-content {
    text-align: center;
    flex: 1;
}

.dataview-recipes .recipe-grid-item .recipe-price {
    font-size: 1.25rem;
    font-weight: 600;
}

@media screen and (max-width: 576px) {
    .dataview-recipes .recipe-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview-recipes .recipe-list-item img {
        margin: 2rem 0;
        max-height: 135px;
        object-fit: scale-down;
        max-width: 75%;
    }

    .dataview-recipes .recipe-list-item .recipe-list-detail {
        text-align: center;
    }

    .dataview-recipes .recipe-list-item .recipe-price {
        align-self: center;
    }

    .dataview-recipes .recipe-list-item .recipe-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview-recipes .recipe-list-item .recipe-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
