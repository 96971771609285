.image-field {
    display: flex;
    flex-flow: column;
    max-width: 200px;
    align-items: center;
}

.image-field img {
    max-height: 100px;
    margin-bottom: 4px;
    object-fit: scale-down;
}
