.recipe-summary {
    border: 1px solid #dee2e6;
    background: #ffffffec;
    flex: 1;
    border-radius: 20px;
}
.recipe-upper-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.recipe-summary .image-frame {
    display: flex;
    flex: 0.3;
    flex-direction: column;
}

.recipe-summary img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    /* margin: 1rem 0; */
    object-fit: cover;
    min-width: 200px;
    max-width: 300px;
}

.recipe-summary h3 {
    margin-block-start: 0.1em;
    margin-block-end: 0.2em;
    border-bottom: 1px solid #304160;
}
.recipe-summary p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.recipe-summary .recipe-name {
    font-size: 1.2rem;
    font-weight: 700;
}

.recipe-summary .recipe-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.recipe-summary .recipe-tags {
    font-weight: 600;
    vertical-align: middle;
}

.recipe-summary .nutrition {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-left: 8px;
    align-self: flex-end;
}

.recipe-summary .image-frame {
    display: flex;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    flex: unset;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}
.recipe-summary .image-frame .recipe-name {
    color: white;
    /* padding-top: 2em; */
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 4em 0 1rem 1em;
    /* //0deg, */
    background-image: linear-gradient(
        hsla(0, 0%, 35.29%, 0) 0%,
        hsla(0, 0%, 34.53%, 0.034375) 16.36%,
        hsla(0, 0%, 32.42%, 0.125) 33.34%,
        hsla(0, 0%, 29.18%, 0.253125) 50.1%,
        hsla(0, 0%, 24.96%, 0.4) 65.75%,
        hsla(0, 0%, 19.85%, 0.546875) 79.43%,
        hsla(0, 0%, 13.95%, 0.675) 90.28%,
        hsla(0, 0%, 7.32%, 0.765625) 97.43%,
        hsla(0, 0%, 0%, 0.8) 100%
    );
}
.recipe-summary .recipe-name {
    font-size: 1.5rem;
    font-weight: 600;
}
@media screen and (max-width: 576px) {
    .recipe-summary .recipe-upper-container {
        flex-direction: column;
    }
    .recipe-summary .recipe-upper-container img {
        margin: 0;
        /* max-height: 235px; */
        /* object-fit: scale-down; */
        /* max-width: 75%;  */
        max-width: 100%;
    }

    .recipe-summary .recipe-list-detail ul,
    .recipe-summary .recipe-list-detail ol {
        display: block;
        /* margin-block-start: 1rem;
        margin-block-end: 1rem;
        margin-inline-start: 0px;
        margin-inline-end: 0px; */
        padding-inline-start: 1.5em;
    }
}
.recipe-summary .recipe-list-detail {
    padding: 8px;
}
