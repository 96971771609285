.tag-list-wrapper .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.tag-list-wrapper .tag-name {
    font-size: 1.5rem;
    font-weight: 700;
}

.tag-list-wrapper .tag-description {
    margin: 0 0 1rem 0;
}

.tag-list-wrapper .tag-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.tag-list-wrapper .tag-category {
    font-weight: 600;
    vertical-align: middle;
}

.tag-list-wrapper .tag-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.tag-list-wrapper .tag-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.tag-list-wrapper .tag-list-item .tag-list-detail {
    flex: 1 1 0;
}

.tag-list-wrapper .tag-list-item .p-rating {
    margin: 0 0 0.5rem 0;
}

.tag-list-wrapper .tag-list-item .tag-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
}

.tag-list-wrapper .tag-list-item .tag-list-action {
    display: flex;
    flex-direction: column;
}

.tag-list-wrapper .tag-list-item .p-button {
    margin-bottom: 0.5rem;
}

.tag-list-wrapper .tag-grid-item .tag-grid-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tag-list-wrapper .tag-grid-item .tag-grid-item-bottom {
    align-items: center;
    justify-content: center;
}

.tag-list-wrapper .tag-grid-item img {
    width: 75%;
    max-width: 275px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.tag-list-wrapper .tag-grid-item .tag-grid-item-content {
    text-align: center;
}

.tag-list-wrapper .tag-grid-item .tag-count {
    font-size: 1.2rem;
    font-weight: 600;
}
.tag-list-wrapper .tag-card-button {
    background: transparent;
    border: 1px solid #dee2e6;
}
/* something like this will increase the spacing without breaking things*/
/* .tag-list-wrapper > div {
    padding: 0.75rem;
} */

.tag-list-wrapper .tag-grid-item {
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0.5rem;
    /* flexy ways don't seem to work*/
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 576px) {
    .tag-list-wrapper .tag-list-item {
        flex-direction: column;
        align-items: center;
    }

    .tag-list-wrapper .tag-list-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .tag-list-wrapper .tag-list-item .tag-list-detail {
        text-align: center;
    }

    .tag-list-wrapper .tag-list-item .tag-price {
        align-self: center;
    }

    .tag-list-wrapper .tag-list-item .tag-list-action {
        display: flex;
        flex-direction: column;
    }

    .tag-list-wrapper .tag-list-item .tag-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    /* #root:before {
        content: '';
        position: absolute;
        background: inherit;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
        filter: blur(10px);
        margin: -20px;
    }  */
}
