.base-card {
    /* background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important; */
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin: 10px; */
    margin: 0;
    /*todo cleanup*/
    /* min-width: 20rem;
    max-height: 23rem; */
    min-width: 20rem;
    max-width: 22rem;
}

.field-set {
    /* background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important; */
    margin: 1em;
}

.recipe-header {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-radius: 3px;
    padding: 0.5em 1em;
    display: flex;
}
.recipe-header h2 {
    margin-block-start: 0.33em;
    margin-block-end: 0.33em;
}
.recipe-header hr {
    width: 98%;
}

.recipe-header-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100%;
}

.recipe-header img {
    max-height: 100px;
    /* margin-bottom: 4px; */
    margin-right: 8px;
    object-fit: scale-down;
}
.recipe-header .recipe-header-main {
    display: flex;
    flex-flow: column;
    flex: 1 1 100%;
}

.recipe-header-details {
    display: flex;
    padding-bottom: 8px;
}
.recipe-header-details label {
    color: #495057;
    margin: 4px 2px 4px 6px;
    font-weight: 600;
}
.recipe-header-source {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    font-size: 1.1em;
}
/* .recipe-card .p-panel .p-panel-content,
.recipe-card .p-panel .p-toggleable-content{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
} */

.recipe-panel {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.p-card.base-card .p-card-content {
    max-height: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding: 0.75rem 0; */
    padding: 0;
}

.p-button.card-button {
    padding: 0;
    border: 0;
    text-align: left;
}

.base-card.p-card .p-card-title {
    font-size: 1.15em;
}

.line-break {
    white-space: pre-wrap;
}
.markdown-summary {
    line-height: 1.3em;
    /* max-height: 100px;
    overflow: hidden; */
    text-overflow: ellipsis;
    text-align: left;
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
