.tag-editor-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.tag-editor-actions {
    /* width: 150px; */
    display: flex;
    flex-flow: column;
    /* justify-content: space-between; */
}
.tag-editor-actions > * {
    margin: 4px 4px;
}
